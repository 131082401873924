import React, { Component } from "react";
import Header from "../components/Header/Header";
import HeaderLinks from "../components/Header/HeaderLinks";
import HeaderLeftLinks from "../components/Header/HeaderLeftLinks";
import { Color as Colors } from "../src/utils/Color";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CustomFont from "../components/CustomFont";
import Footer from "../components/Footer/Footer";
import Button from "@mui/material/Button";
import Router from "next/router";
import Image from "next/image";
import Box from "@mui/material/Box";
import styled  from "styled-components";
const FooterStateContainer = styled.div`
  font-size: 0.9rem;
  margin: 7px 0;
  color: ${Colors.primaryColor};
  &:hover {
    cursor: pointer;
  }
`;
class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = { countdown: 10 };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.countdown === 1) {
          clearInterval(this.interval);
          Router.push("/");
        }
        return { countdown: prevState.countdown - 1 };
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onClickHome = () => {
    Router.push("/");
  };
  render() {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          minHeight: "100vh",
          background: "white"
        }}
      >
        <Header
          ref={this.headerRef}
          key="404"
          brand={true}
          rightLinks={<HeaderLinks />}
          leftLinks={<HeaderLeftLinks />}
          fixed
          color="white"
          style={{
            marginBottom: 0,
            boxShadow: "none",
            borderBottom: `1px solid ${Colors.borderColor}`
          }}
        />
        <Header
          ref={this.headerRef}
          key="404"
          brand={true}
          rightLinks={<HeaderLinks />}
          leftLinks={<HeaderLeftLinks />}
          color="white"
          style={{
            marginBottom: 0,
            boxShadow: "none",
            position: "unset",
            zIndex: 1,
              visibility:"hidden"
          }}
        />
        <Container>
          <Grid container style={{ marginTop: "3rem",marginBottom: "3rem" }}>
            <Grid item md={12} xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Image 
                src="/page-not-found.webp" 
                alt="Page Not Found" 
                width={500} 
                height={380} 
                priority={true}
                unoptimized={true}
                style={{ maxWidth: "100%", height: "auto" }} 
                />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              style={{ textAlign: "center" }}>
              <CustomFont size="xalarge" styles={{ fontWeight: "600" }}>
                <span style={{ color: Colors.primaryColor }}>Sorry!</span> this page isn’t available
              </CustomFont>
              <CustomFont styles={{ marginTop: "10px" }}>
                {`The page you were looking for couldn’t be found`}
              </CustomFont>
              <Button
                onClick={this.onClickHome}
                color="primary"
                variant="contained"
                style={{ fontWeight: "600", marginTop: "20px", width: "200px", paddingTop:"10px", paddingBottom:"10px" }}
              >
                Go To Homepage {this.state.countdown}...
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ borderTop: "1px solid rgb(221, 221, 221)" }}>
            <Grid container style={{ marginTop: "2rem", marginBottom: "2rem" }} spacing={2}>
              <Grid item md={3} sm={6} xs={12}>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/apartment-condo-service-residence-for-sale/in-klcc-state-kuala-lumpur/tenure-leasehold`}>
                      Leasehold Tenure in KLCC
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/apartment-condo-service-residence-for-rent/in-klcc-state-kuala-lumpur/tenure-bumi-lot`}>
                      Bumi Lot Tenure in KLCC
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/condo-for-rent/in-klcc-state-kuala-lumpur/tenure-leasehold`}>
                    Leasehold Tenure in KLCC
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/condo-for-sale/in-klcc-state-kuala-lumpur/tenure-leasehold`}>
                    Leasehold Tenure in KLCC
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/1-storey-terrace-for-rent/in-taman-desa-tasek-state-kuala-lumpur/tenure-freehold`}>
                    Freehold Tenure in Taman Desa Tasek
                    </a>
                  </FooterStateContainer>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/2-storey-semi-detached-for-sale/in-taman-desa-tasek-state-kuala-lumpur/tenure-bumi-lot`}>
                    Bumi Lot Tenure in Taman Desa Tasek
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/1-storey-terrace-for-rent/in-sg-jb-sez-state-johor/furnishing-unfurnished`}>
                    Unfurnished Furnishing in SG JB SEZ
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/1-storey-terrace-for-rent/in-sg-jb-sez-state-johor/furnishing-fully-furnished`}>
                    Fully Furnished Furnishing in SG JB SEZ
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/2-storey-terrace-for-rent/in-sg-jb-sez-state-johor/furnishing-partially-furnished`}>
                    Partially Furnished Furnishing in SG JB SEZ
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/empty-office-for-rent/in-puncak-jalil-state-selangor/bathroom-min-5`}>
                    Min 5 Bathroom in Puncak Jalil
                    </a>
                  </FooterStateContainer>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/condo-big-balcony-for-sale/in-johor-bahru-city-centre-state-johor/bedroom-min-4`}>
                    Min 4 Bedroom in Johor Bahru City Centre
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/condominium-for-rent/in-genting-skyworlds-state-pahang/price-below-8500`}>
                    Price Below 8500 in Genting SkyWorlds
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/industrial-property-for-rent/in-pjs-10-state-selangor/bedroom-min-1`}>
                    Min 1 Bedroom in PJS 10
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/detached-factory-for-rent/in-subang-bestari-state-selangor/bedroom-min-2`}>
                    Min 2 Bedroom in Subang Bestari
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/warehouse-for-rent/in-sabak-bernam-state-selangor/bedroom-min-2`}>
                    Min 2 Bedroom in Sabak Bernam
                    </a>
                  </FooterStateContainer>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/commercial-property-for-sale/in-taman-ungku-tun-aminah-state-johor/bathroom-min-5`}>
                    Min 5 Bathroom in Taman Ungku Tun Aminah
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/studio-unit-for-rent/in-usj-25-state-selangor/bedroom-min-1`}>
                    Min 1 Bedroom in USJ 25
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/shoplot-for-rent/in-chini-state-pahang/price-between-2000-2500`}>
                    Price Between 2000-2500 in Chini
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/residential-for-rent/in-bandar-sunway-state-selangor/price-above-500`}>
                    Price Above 500 in Bandar Sunway
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}2025/industrial-for-rent/in-bandar-sunway-state-selangor/price-below-9500`}>
                    Price Below 9500 in Bandar Sunway
                    </a>
                  </FooterStateContainer>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Footer />
      </div>
      
    );
  }
}

export default NotFound;
